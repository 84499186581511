import React, { useEffect, useReducer, useRef, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Oceanicoin from './assets/worth/oceanicoin.svg';

const bottleWeightNormal = 18.5;
const surfacePollutionLevel = 80;
const oxygenLevel = 66;

declare global {
  interface Window {
    Telegram: {
      WebApp: {
        initData: string;
        initDataUnsafe: {
          user: {
            id: number;
            first_name: string;
            last_name: string;
            username: string;
            language_code: string;
          };
        };
        expand: () => void;
        sendData: (data: string) => void;
      };
    };
  }
}

function App() {

  const globalTime = useRef<NodeJS.Timer>();
  const [collectedPlastics, setCollectedPlastics]  = useState<number>(0);
  const [displayWeight, setDisplayWeight] = useState<number>(2000);
  const [collectedWorth, setcollectedWorth] = useState<number>(0);

  //#region Telegram userData
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userId, setUserId] = useState('');
  //#endregion

  //const [collectedWorthDisplayable, setcollectedWorthDisplayable] = useState<string>('0');
  useEffect(() => {
    const weight = bottleWeightNormal * collectedPlastics / 1000;
    setDisplayWeight(weight);
    setcollectedWorth(weight * 250 / 1000);
    globalTime.current = setInterval(() => {
      // ...
    }, 1000);    
    //setcollectedWorthDisplayable(toWorthString(weight * 250 / 1000));
    
    //#region Telegram userData fetcher
    // Check if the Telegram WebApp object exists (it will be available only when opened in the Telegram app)
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      
      // Extract user data from the WebApp initDataUnsafe object
      const user = tg.initDataUnsafe?.user;
      if (user) {
        setUsername(user.username);
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setUserId(user.id.toString());
      }
      
      // Expand the WebApp to fill the entire screen
      tg.expand();
    }
    //#endregion
  },[]);

  useEffect(() => {
    const weight = bottleWeightNormal * collectedPlastics / 1000;
    setDisplayWeight(weight);
    setcollectedWorth(weight * 250 / 1000);
    globalTime.current = setInterval(() => {
      // ...
    }, 1000);    
    //setcollectedWorthDisplayable(toWorthString(weight * 250 / 1000));    
  },[collectedPlastics, username, firstName, lastName, userId]);

  const pickPlastic = () => {
    setCollectedPlastics(collectedPlastics + 1);
  }

  const toWeightString = (weight: number) => {
    const result = weight.toString();
    if(weight < 1)
      return result.substring(0, 4);
    else if(weight < 1000)
      return Math.floor(weight).toString();
    else if(1000 <= weight && weight < 1000000)
      return Math.floor(weight / 1000) + 'K';
    else if(1000000 <= weight && weight < 1000000000)
      return Math.floor(weight / 1000000) + 'M';
    else if(1000000000 <= weight && weight < 1000000000000)
      return Math.floor(weight / 1000000000) + 'B';
    return result;
  }

  const toWorthString = (worth: number) => {
    const result = worth.toString();
    if(worth < 1)
      return result.substring(0, 4);
    else if(worth < 1000)
      return Math.floor(worth).toString();
    else if(1000 <= worth && worth < 1000000)
      return Math.floor(worth / 1000) + 'K';
    else if(1000000 <= worth && worth < 1000000000)
      return Math.floor(worth / 1000000) + 'M';
    else if(1000000000 <= worth && worth < 1000000000000)
      return Math.floor(worth / 1000000000) + 'B';
    return result;
  }

  return (
    <div className='container' onClick={() => pickPlastic()}>
    
    <div className='userData'>
      <h1>Telegram WebApp User Data</h1>
      <p><strong>Username:</strong> {username}</p>
      <p><strong>First Name:</strong> {firstName}</p>
      <p><strong>Last Name:</strong> {lastName}</p>
      <p><strong>User ID:</strong> {userId}</p>
    </div>
    
      <div className='globalLight'>        
      </div>

      <div className='globalFloor'>
        {/* <img src={require('./assets/floor.png')} height='100%' width='100%' style={{ opacity: '1' }} /> */}
      </div>

      #region dynamic Algaes
      <div className='dynamicAlgae' style={{ zIndex: '2', bottom: '10px', left: '15%' }}>
        <img src={require('./assets/Algae.png')} height='100%' style={{ opacity: '0.8' }} />
      </div>

      <div className='dynamicAlgae' style={{ zIndex: '2', bottom: '10px', left: '50%' }}>
        <img src={require('./assets/Algae.png')} height='70%' style={{ opacity: '0.8' }} />
      </div>

      <div className='dynamicAlgae' style={{ zIndex: '2', bottom: '10px', right: '10%' }}>
        <img src={require('./assets/Algae.png')} height='70%' style={{ opacity: '0.8' }} />
      </div>
      #endregion

      #region static Algaes
      <div className='algae' style={{ zIndex: '2', bottom: '0px', left: '0px' }}>
        <img src={require('./assets/Algae0130.png')} height='80%' style={{ opacity: '1' }} />
      </div>

      <div className='algae' style={{ zIndex: '1', bottom: '0px', left: '25%' }}>
        <img src={require('./assets/Algae0090.png')} height='100%' style={{ opacity: '1' }} />
      </div>

      <div className='algae' style={{ zIndex: '1', bottom: '0px', right: '25%' }}>
        <img src={require('./assets/Algae0000.png')} height='100%' style={{ opacity: '1' }} />
      </div>
      #endregion
      
      #region bubbles
      <div className='bubbles' style={{ zIndex: '0', bottom: '10%', left: '0px' }}>
        <img src={require('./assets/bubbles.gif')} height='100%' style={{ opacity: '0.7', maxWidth: '95%' }} />
      </div>

      <div className='bubbles' style={{ zIndex: '0', bottom: '35%', left: '25%' }}>
        <img src={require('./assets/bubbles.gif')} height='100%' style={{ opacity: '0.7', maxWidth: '95%' }} />
      </div>

      <div className='bubbles' style={{ zIndex: '0', bottom: '20%', left: '70%' }}>
        <img src={require('./assets/bubbles.gif')} height='100%' style={{ opacity: '0.7', maxWidth: '95%' }} />
      </div>
      #endregion

      #region user worth
      <div className='worthContainer'>
        <div className='worthData'>
          <div className='worthValue'>
            <div className='weightData'>
              {displayWeight < 1 ? displayWeight.toString().substring(0, 4) : (displayWeight < 1000 ? Math.floor(displayWeight) : Math.floor(displayWeight / 1000) )} { displayWeight < 1000 ? 'Kg' : 'T'}
            </div>
            <div className='coinData'>
              <img src={Oceanicoin} height='48px' style={{ opacity: '1' }} />
              <span style={{ width: '10px'}}></span>
              {toWorthString(collectedWorth)}
            </div>
          </div>
        </div>
      </div>
      #endregion

      #region environment statistics
      <div className='O2Container'>
        <div className='O2Cylinder' style={{ height: '100px' }}>
          <div className='O2Data'>
            Oxygen {oxygenLevel}%
          </div>
        </div>
      </div>
      <div className='pollutionContainer'>
        <div className='pollutionCylinder' style={{ backgroundColor: 'rgb(0, 50, 0)' }}>
          <div className='pollutionData'>
            Pollution {surfacePollutionLevel}%
          </div>
        </div>
      </div>
      #endregion
    </div>
  );
}

export default App;
